<template>
  <div class="project-customer">
    <b-row>
      <b-col lg="6" md="6" sm="12">
        <legend>
          <strong>Stammdaten</strong>
        </legend>

        <b-form-group
          horizontal
          :label="$t('salutation')"
          label-for="salutation"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('salutation')"
        >
          <multiselect
            v-model="project.customer.salutation"
            id="salutation"
            :options="getEnumValues('Salutation')"
            :placeholder="$t('select_salutation')"
            :allow-empty="false"
            :show-labels="false"
            deselectLabel
          >
            <template slot="singleLabel" slot-scope="{ option }">{{ $t(option) }}</template>
            <template slot="option" slot-scope="{ option }">{{ $t(option) }}</template>
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('company')"
          label-for="company"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.customer.company"
            type="text"
            :placeholder="$t('company')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('firstname')"
          label-for="firstname"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('firstname')"
        >
          <b-form-input
            :state="requirementsValidState('firstname')"
            v-model="project.customer.firstname"
            type="text"
            :placeholder="$t('firstname')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('lastname')"
          label-for="lastname"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('lastname')"
        >
          <b-form-input
            v-model="project.customer.lastname"
            type="text"
            :placeholder="$t('lastname')"
            :state="requirementsValidState('lastname')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('street')"
          label-for="street"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('street')"
        >
          <b-form-input
            v-model="project.customer.street"
            type="text"
            :placeholder="$t('street')"
            :state="requirementsValidState('street')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('streetNumber')"
          label-for="streetNumber"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.customer.streetNumber"
            type="text"
            :placeholder="$t('streetNumber')"
          />
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('streetNumberSuffix')"
          label-for="streetNumberSuffix"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.customer.streetNumberSuffix"
            type="text"
            :placeholder="$t('streetNumberSuffix')"
          />
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('zip')"
          label-for="zip"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('zip')"
        >
          <b-form-input
            v-model="project.customer.zip"
            type="text"
            :placeholder="$t('zip')"
            :state="requirementsValidState('zip')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('city')"
          label-for="city"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('city')"
        >
          <b-form-input
            v-model="project.customer.city"
            type="text"
            :placeholder="$t('city')"
            :state="requirementsValidState('city')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('cityPart')"
          label-for="cityPart"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.customer.cityPart"
            type="text"
            :placeholder="$t('cityPart')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('longitude')"
          label-for="longitude"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.customer.longitude"
            type="text"
            :placeholder="$t('longitude')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('latitude')"
          label-for="latitude"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.customer.latitude"
            type="text"
            :placeholder="$t('latitude')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('phone')"
          label-for="phone"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('phone')"
        >
          <b-form-input
            v-model="project.customer.phone"
            type="text"
            :placeholder="$t('phone')"
            :state="requirementsValidState('phone')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('phone2')"
          label-for="phone2"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input v-model="project.customer.phone2" type="text" :placeholder="$t('phone2')" />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('phone3')"
          label-for="phone3"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input v-model="project.customer.phone3" type="text" :placeholder="$t('phone3')" />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('mobile')"
          label-for="mobile"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input v-model="project.customer.mobile" type="text" :placeholder="$t('mobile')" />
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('mobile2')"
          label-for="mobile2"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.customer.mobile2"
            type="text"
            :placeholder="$t('mobile2')"
          />
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('email')"
          label-for="email"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('email')"
        >
          <b-form-input
            v-model="project.customer.email"
            type="text"
            :placeholder="$t('email')"
            :state="requirementsValidState('email')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('email2')"
          label-for="email2"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input v-model="project.customer.email2" type="text" :placeholder="$t('email2')" />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('birthdayAt')"
          label-for="birthdayAt"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <Calendar
            v-model="birthdayAt"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            :placeholder="$t('date')"
            :showWeek="true"
            :yearNavigator="true"
            :monthNavigator="true"
            yearRange="1930:2021"
          >
          </Calendar>
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('installationAddressState')"
          label-for="installationAddressState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <SelectButton
            v-model="project.installationAddressState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </b-form-group>
        <div v-if="project.installationAddressState === 'YES'">
          <b-form-group
            horizontal
            :label="$t('street')"
            label-for="street"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="project.installationAddress.street"
              type="text"
              :placeholder="$t('street')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('streetNumber')"
            label-for="streetNumber"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="project.installationAddress.streetNumber"
              type="text"
              :placeholder="$t('streetNumber')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('streetNumberSuffix')"
            label-for="streetNumberSuffix"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="project.installationAddress.streetNumberSuffix"
              type="text"
              :placeholder="$t('streetNumberSuffix')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('zip')"
            label-for="zip"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="project.installationAddress.zip"
              type="text"
              :placeholder="$t('zip')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('city')"
            label-for="city"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="project.installationAddress.city"
              type="text"
              :placeholder="$t('city')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('cityPart')"
            label-for="cityPart"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="project.installationAddress.cityPart"
              type="text"
              :placeholder="$t('cityPart')"
            />
          </b-form-group>
        </div>
      </b-col>
      <b-col lg="6" md="6" sm="12">
        <legend>
          <strong>Weiterführende Kundendaten</strong>
        </legend>

        <b-form-group
          horizontal
          :label="$t('taxNumber')"
          label-for="taxNumber"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.customer.taxNumber"
            type="text"
            :placeholder="$t('taxNumber')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('bank')"
          label-for="bank"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input v-model="project.customer.bank" type="text" :placeholder="$t('bank')" />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('iban')"
          label-for="iban"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input v-model="project.customer.iban" type="text" :placeholder="$t('iban')" />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('bic')"
          label-for="bic"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input v-model="project.customer.bic" type="text" :placeholder="$t('bic')" />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('accountHolder')"
          label-for="accountHolder"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.customer.accountHolder"
            type="text"
            :placeholder="$t('accountHolder')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('field')"
          label-for="field"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input v-model="project.customer.field" type="text" :placeholder="$t('field')" />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('fieldSector')"
          label-for="fieldSector"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('fieldSector')"
        >
          <b-form-input
            v-model="project.customer.fieldSector"
            type="text"
            :placeholder="$t('fieldSector')"
            :state="requirementsValidState('fieldSector')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('boundary')"
          label-for="boundary"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('boundary')"
        >
          <b-form-input
            v-model="project.customer.boundary"
            type="text"
            :placeholder="$t('boundary')"
            :state="requirementsValidState('boundary')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('electricMeterNumber')"
          label-for="electricMeterNumber"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('electricMeterNumber')"
        >
          <b-form-input
            v-model="project.customer.electricMeterNumber"
            type="text"
            :placeholder="$t('electricMeterNumber')"
            :state="requirementsValidState('electricMeterNumber')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('electricMeterNumberDescription')"
          label-for="electricMeterNumberDescription"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.customer.electricMeterNumberDescription"
            type="text"
            :placeholder="$t('electricMeterNumberDescription')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('electricMeterNumber2')"
          label-for="electricMeterNumber2"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.customer.electricMeterNumber2"
            type="text"
            :placeholder="$t('electricMeterNumber2')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('electricMeterNumber2Description')"
          label-for="electricMeterNumber2Description"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.customer.electricMeterNumber2Description"
            type="text"
            :placeholder="$t('electricMeterNumber2Description')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('electricMeterNumber3')"
          label-for="electricMeterNumber3"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.customer.electricMeterNumber3"
            type="text"
            :placeholder="$t('electricMeterNumber3')"
          />
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('electricMeterNumber3Description')"
          label-for="electricMeterNumber3Description"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.customer.electricMeterNumber3Description"
            type="text"
            :placeholder="$t('electricMeterNumber3Description')"
          />
        </b-form-group>

        <legend>
          <strong>{{ $t('notes') }}</strong>
        </legend>
        <b-form-group horizontal breakpoint="md">
          <ckeditor
            :editor="editor"
            v-model="project.customerDescription"
            :config="editorConfig"
          ></ckeditor>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Calendar from 'primevue/calendar';
import Multiselect from 'vue-multiselect';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import SelectButton from 'primevue/selectbutton';

export default {
  components: {
    Multiselect,
    Calendar,
    SelectButton,
    ckeditor: CKEditor.component,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      editor: InlineEditor,
      editorConfig: {},
    };
  },
  computed: {
    ...mapGetters(['getEnumValues']),
    birthdayAt: {
      get() {
        return this.$calendarFormat(this.project.customer.birthdayAt);
      },
      set(value) {
        this.project.customer.birthdayAt = value;
      },
    },

    validState() {
      return this.project.customer.firstname.length >= 1;
    },
    /**
     * @todo DRY
     */
    invalidFeedbackNA() {
      return 'Voraussetzung NA';
    },
  },
  methods: {
    ...mapActions(['fetchEnumValues']),
    /**
     * check requirementsValidState to show mark input fields
     * @todo DRY
     */
    requirementsValidState(field) {
      if (!this.project.netRegistrationRequirements) return false;

      const requirement = this.project.netRegistrationRequirements.find(
        (requirement) => requirement.name === field
      );
      return requirement && requirement.fullfilledState === 'YES' ? true : false;
    },
  },

  async mounted() {
    await this.fetchEnumValues('Salutation');
  },
};
</script>
